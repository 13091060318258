import * as React from 'react';
import { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InstagramFooterMobile from '../../images/instagram-footer-mobile.png';
import FacebookFooterMobile from '../../images/facebook-footer-mobile.png';

class Footer extends Component {
    handleFooterLogoClick() {
        window.scrollTo(0, 0);
    }

    // footerCodes = {
    //   "site-map" : "US-UNBC-0350 05/22", // MAR
    //   "home" : "US-UNBC-0350 05/22", // MAR
    //   "about" : "US-UNBC-0350 05/22", // MAR
    //   "404" : "US-UNBC-0307 02/22", // FEB

    //   // HIV Landscape
    //   "ending-hiv" : "US-UNBC-0307 02/22", // FEB
    //   "history" : "US-UNBC-0307 02/22", // FEB
    //   "care-continuum" : "US-UNBC-0350 05/22", // MAR
    //   "testing" : "US-UNBC-0307 02/22", // FEB
    //   "state" : "US-UNBC-0307 02/22", // FEB
    //   "content-index": "US-UNBC-0350 05/22", // MAR

    //   // Community Involvement
    //   "blind-angels" : "US-UNBC-0350 05/22", // MAR
    //   "events" : "US-UNBC-0307 02/22", // FEB
    //   "living-mosaic" : "US-UNBC-0307 02/22", // FEB
    //   "voices-of-strength" : "US-UNBC-0307 02/22",

    //   // Blind Angels (Article Pages)
    //   "dazon" : "US-UNBC-0350 05/22", // MAR
    //   "jose" : "US-UNBC-0307 02/22", // FEB
    //   "tony" : "US-UNBC-0307 02/22", // FEB
    //   "zakia" : "US-UNBC-0307 02/22" // FEB
    // }

    render() {
        return (
            <footer
                className={`
          container-fluid
          ${this.props.mobileMenuOpen ? 'mobile-menu-open' : ''}
          ${this.props.isSiteMap404 ? 'isSiteMap404' : 'not true'}
        `}
            >
                <Container>
                    <Row>
                        <Col lg={8} className='m-auto text-left text-md-center '>
                            <div className='social'>
                                <a href='https://www.instagram.com/gileadhivus/ ' target='_blank'>
                                    <img
                                        className={`${this.props.mobileMenuOpen ? 'd-inline-block' : 'd-none'
                                            } instagram`}
                                        src={InstagramFooterMobile}
                                        alt='instagram icon'
                                    />
                                    <img
                                        className={`${this.props.mobileMenuOpen ? 'd-none' : 'd-inline-block'
                                            } instagram`}
                                        src='https://s3.amazonaws.com/gileadhiv.com/images/instagram.png'
                                        height='49'
                                        width='49'
                                        alt='instagram icon'
                                    />
                                </a>
                                <a href='https://www.facebook.com/GileadHIVUS/ ' target='_blank '>
                                    <img
                                        className={`${this.props.mobileMenuOpen ? 'd-inline-block' : 'd-none'
                                            } facebook`}
                                        src={FacebookFooterMobile}
                                        alt='facebook icon'
                                    />
                                    <img
                                        className={`${this.props.mobileMenuOpen ? 'd-none' : 'd-inline-block'
                                            } facebook`}
                                        // src="https://s3.amazonaws.com/gileadhiv.com/images/instagram.png"
                                        src='https://s3.amazonaws.com/gileadhiv.com/images/facebook.png'
                                        height='49'
                                        width='49'
                                        alt='facebook icon'
                                    />
                                </a>
                            </div>
                            <a onClick={this.handleFooterLogoClick} href='/'>
                                <img
                                    className='footer-logo'
                                    src='/gilead-hiv-white.svg'
                                    alt='Image of GILEAD HIV logo'
                                />
                            </a>
                            <div className='links d-none d-md-block'>
                                <a
                                    target='_blank'
                                    href='https://www.gilead.com/privacy/website-privacy-statement'
                                >
                                    Privacy Statement
                                </a>
                                <span className='divider'>|</span>
                                <a 
                                    target='_blank' 
                                    href='https://www.gilead.com/privacy-statements/consumer-health-data-privacy-policy'>
                                        Consumer Health Data Privacy Policy
                                </a>
                                <span className='divider'>|</span>
                                <a href='/sitemap'>Site Map</a>
                                <span className='divider'>|</span>
                                <a href='https://www.gilead.com/terms-of-use'>Terms of Use</a>
                                <span className='divider'>|</span>
                                <a href='https://www.gilead.com/utility/contact'>Contact Us</a>
                            </div>

                            <div className='links d-block d-md-none mt-4 pt-2'>
                                <a
                                    target='_blank'
                                    href='https://www.gilead.com/privacy/website-privacy-statement'
                                >
                                    Privacy Statement
                                </a>
                                <a 
                                    target='_blank' 
                                    href='https://www.gilead.com/privacy-statements/consumer-health-data-privacy-policy'>
                                        Consumer Health Data Privacy Policy
                                </a>
                                <a href='/sitemap'>Site Map</a>
                                <a className='mt-2' href='https://www.gilead.com/terms-of-use'>
                                    Terms of Use
                                </a>
                                <a className='mt-2' href='https://www.gilead.com/utility/contact'>
                                    Contact Us
                                </a>
                            </div>
                            <p>
                                The information on this site is intended for audiences in the United
                                States only. The content on this site may not apply to
                                <span className='no-break'> non-U.S.</span> audiences as regulatory
                                control, legal requirements, and/or medical practices may vary in
                                other countries.
                            </p>
                            <p>
                                WHAT WE LIVE FOR, HEALTHYSEXUAL, RADIAN, COMPASS INITIATIVE, HIV AGE
                                POSITIVELY, TRANSCEND, the & Design, GILEAD, and the GILEAD Logo are
                                trademarks of Gilead Sciences, Inc. All other marks referenced
                                herein are the property of their respective owners.
                                &copy;&nbsp;2024&nbsp;Gilead&nbsp;Sciences,&nbsp;Inc. All rights
                                reserved.&nbsp;
                                <span>US-UNBC-2332 08/24</span>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default Footer;
